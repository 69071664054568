//Dependencies
import { combineReducers } from 'redux';

//Subcomponents
import loginReducer from "./accountManagement/loginReducer";
import partnerReducer from "./partner/partnerReducer";
import profilerHomeReducer from "./profiler/profilerHomeReducer";
import profilerScheduleReducer from "./profiler/profilerScheduleReducer";
import adviserReducer from './adviser/adviserReducer';
import adminReducer from './admin/adminReducer';
import headerMenuReducer from "./layout/headerMenuReducer";
import historyReducer from "./shared/historyReducer";
import profilerRequestsHistoryReducer from "./profiler/profilerRequestsHistoryReducer";
import designerAdminReducer from "./designerAdmin/designerAdminReducer";

//Reducers
export default combineReducers({
  login: loginReducer,
  partner: partnerReducer,
  profilerHome: profilerHomeReducer,
  profilerSchedule: profilerScheduleReducer,
  profilerRequestsHistory: profilerRequestsHistoryReducer,
  adviser: adviserReducer,
  admin: adminReducer,
  headerMenu: headerMenuReducer,
  history: historyReducer,
  designerAdmin: designerAdminReducer
});