export const equalMeetingsLists = (oldMeetings,  newMeetings) => {
  if(oldMeetings === undefined || newMeetings === undefined){
    return false;
  }
  if(oldMeetings.length !== newMeetings.length){
    return false;
  }

  newMeetings.forEach( meeting => {
    if( !listContainsMeeting(oldMeetings, meeting)){
      return false;
    }
  });

  return true;
};

const listContainsMeeting = (list, meeting) => {
  const existingMeetings = list.filter( obj => obj.meetingId === meeting.meetingId);
  return existingMeetings.length > 0;
};

export const serviceInList = (list, serviceName) => {
  return list.findIndex( service => service.name === serviceName) !== -1;
};