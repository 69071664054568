// Types
import { profilerTypes } from '../../types';

// Initial State
const initialState = {
  isFetching: false,
  isDefault: false, //false: edit, true: create
  morningEvents: [],
  afternoonEvents: [],
  eventsSchedule: [],
  summary: []
};

export default function profilerScheduleReducer (state = initialState, action) {
  switch (action.type) {
    case profilerTypes.GET_SCHEDULE_PARAMS:
      return {
        ...state,
        isFetching: action.isFetching,
        isDefault: action.isDefault,
        morningEvents: action.morningEvents,
        afternoonEvents: action.afternoonEvents
      };
    case profilerTypes.ADD_SCHEDULE:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case profilerTypes.GET_SCHEDULE:
      return {
        ...state,
        isFetching: action.isFetching,
        eventsSchedule: action.eventsSchedule
      };
    case profilerTypes.UPDATE_SCHEDULE:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case profilerTypes.GET_SCHEDULE_SUMMARY:
      return {
        ...state,
        isFetching: action.isFetching,
        summary: action.summary
      };
    default:
      return state;
  }
}