// Types
import { headerMenuTypes } from '../../types';

// Initial State
const initialState = {
  currentRoute: ''
};

export default function headerMenuReducer (state = initialState, action) {
  switch (action.type) {
    case headerMenuTypes.CURRENT_KEY_ACTIVE:
      return {
        ...state,
        currentRoute: action.currentKey
      };
    default:
      return state;
  }
}