// Types
import { historyTypes } from '../../types';

// Initial State
const initialState = {
  isFetching: false,
  requestsRecord: {},
  requestsReportParams: {}
};

export default function profilerRequestsHistoryReducer (state = initialState, action) {
  switch (action.type) {
    case historyTypes.GET_REQUESTS_REPORT:
      return {
        ...state,
        isFetching: action.isFetching,
        requestsRecord: action.requestsRecord
      };
    case historyTypes.GET_REQUESTS_REPORT_PARAMS:
      return {
        ...state,
        isFetching: action.isFetching,
        requestsReportParams: action.requestsReportParams
      };
    default:
      return state;
  }
}