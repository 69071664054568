//Libraries

//Styles
import '../../../../styles/adviser/adviser.css';
import sound from '../../../../assets/sounds/profilerNotification.mp3';

let audio = undefined;

const NewClientNotification = () =>{
  audio = new Audio(sound);
  audio.play();
};

export default NewClientNotification;