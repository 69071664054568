// Types
import { profilerTypes } from '../../types';
import NewClientNotification from "../../../../components/ui/profiler/subcomponents/NewClientNotification";

// Initial State
const initialState = {
  isFetching: false,

  advisersList: [],
  waitingCustomersList: [],
  canceledMessage: [],
  attendingCustomersList: [],
  inMeetingList: [],
  availableKeys: true,

  idleTimeout: 300000
};

export default function profilerHomeReducer (state = initialState, action) {
  switch (action.type) {
    case profilerTypes.GET_ALL_ADVISERS:
      return {
        ...state,
        advisersList: action.advisersList
      };
    case profilerTypes.GET_ALL_TO_ASSIGN:
      if(state.waitingCustomersList.length !== action.waitingCustomersList.length &&
          state.waitingCustomersList.length < action.waitingCustomersList.length){
        NewClientNotification();
      }
      return {
        ...state,
        isFetching: action.isFetching,
        waitingCustomersList: action.waitingCustomersList,
        canceledMessage: action.canceledMessage,
        availableKeys: action.availableKeys
      };
    case profilerTypes.DELETE_MEETING_REQUEST:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case profilerTypes.ASSIGN:
      return {
        ...state,
        isFetching: action.isFetching
      };
    case profilerTypes.GET_ALL_ASSIGNED_MEETING:
      return {
        ...state,
        isFetching: action.isFetching,
        attendingCustomersList: action.attendingCustomersList
      };

    case profilerTypes.SET_IS_FETCHING_PROF:
      return {
        ...state,
        isFetching: action.flag
      };

    case profilerTypes.SET_IDLE_TIMEOUT:
      return {
        ...state,
        idleTimeout: action.idleTimeout
      };

    case profilerTypes.GET_ALL_IN_A_MEETING:
      return {
        ...state,
        isFetching: action.isFetching,
        inMeetingList: action.inMeetingList
      };

    case profilerTypes.FREE_MEETING:
      return {
        ...state,
        isFetching: action.isFetching
      };

    default:
      return state;
  }
}