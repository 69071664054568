//Constants
import {AdminTypes as C} from  '../../types';

const initialState = {
  isLoading: false,

  getLogosResponse: null,
  updateLogosResponse: null,
  getFirstParamsTextResponse: null,
  updateParamsTextResponse: null,
  getMultimediaParamsResponse: null,
  updateMultimediaParamsResponse: null,
  getTermsCondsResponse: null,
  updateTermsCondsResponse: null,
  getLinksResponse: null,
  getScheduleValues: null,
  getScheduleValuesResponse: null,
  getServicesResponse: null,
  getTimesoutResponse: null,
  keys: null,
  getAdvisorsWithoutKeys: null,
  getSharedAccountResponse: null,
  urlsList: [],
  urlByIdInfo: {},
  videoCallParams: [],
  isEmbeddedMeeting: true
};

export default function adminReducer(state = initialState, action){
  switch (action.type) {
    case C.GET_LOGOS:
      return{
        ...state,
        getLogosResponse: action.payload,
      };
    case C.SET_LOGOS:
      return{
        ...state,
        isLoading: action.isLoading,
        updateLogosResponse: action.payload,
      };
    case C.GET_PARAMS_TEXT:
      return{
        ...state,
        getFirstParamsTextResponse: action.payload,
      };
    case C.SET_PARAMS_TEXT:
      return{
        ...state,
        isLoading: action.isLoading,
        updateParamsTextResponse: action.payload,
      };
    case C.GET_MULTIMEDIA:
      return{
        ...state,
        getMultimediaParamsResponse: action.payload,
      };
    case C.UPDATE_MULTIMEDIA:
      return{
        ...state,
        isLoading: action.isLoading,
        updateMultimediaParamsResponse: action.payload,
      };    
    case C.GET_TERMS_CONDS:
      return{
        ...state,
        getTermsCondsResponse: action.payload,
      };
    case C.UPDATE_TERMS_CONDS:
      return{
        ...state,
        isLoading: action.isLoading,
        updateTermsCondsResponse: action.payload,
      };
    case C.GET_LINKS:
      return{
        ...state,
        getLinksResponse: action.payload,
      };
    case C.GET_SCHEDULE:
      return{
        ...state,
        getScheduleValuesResponse: action.payload,
      };
    case C.GET_SERVICES:
      return{
        ...state,
        getServicesResponse: action.payload,
      };
    case C.GET_KEY:
      return{
        ...state,
        isLoading: action.isLoading,
        keys: action.payload,
      };
    case C.GET_TIMEOUT: 
      return{
        ...state,
        getTimesoutResponse: action.payload,
      }; 
    case C.RESET_VALUES:
      return{
        ...state,
        getLogosResponse: null,
      };

    case C.GET_VIDEOCALL_URLS:
      return{
        ...state,
        isLoading: action.isLoading,
        urlsList: action.urlsList
      };
    case C.GET_URL_BY_ID:
      return{
        ...state,
        isLoading: action.isLoading,
        urlByIdInfo: action.urlByIdInfo
      };
    case C.UPDATE_VIDEOCALL_URL:
      return{
        ...state,
        isLoading: action.isLoading
      };
    case C.GET_PARAMS_VIDEOCALL_URL:
      return{
        ...state,
        isLoading: action.isLoading,
        videoCallParams: action.videoCallParams
      };
    case C.GET_EMBEDDED_MODE:
      return{
        ...state,
        isLoading: action.isLoading,
        isEmbeddedMeeting: action.isEmbeddedMeeting
      };
    case C.UPDATE_EMBEDDED_MODE:
      return{
        ...state,
        isLoading: action.isLoading
      };
    default:
      return state;
  }
}