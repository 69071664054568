//Constants
import { AdviserTypes as C} from "../../types";
import { equalMeetingsLists } from "../../../../lib/adviserUtils/adviserUtils";
import MeetingNotification from "../../../../components/ui/adviser/subcomponents/MeetingsNotification";

const initialState = {
  history: [],
  meetings: [],
  services: [],
  notifications: 0,
  isLoading: false,
  servicesModalVisible: false,
  meetingUrl: null,
  meetingLogId: null
};

export default function adviserReducer(state = initialState, action){
  switch (action.type) {
    case C.SET_ADVISER_HISTORY:
      return {
        ...state,
        history: action.history
      };

    case C.SET_ADVISER_MEETINGS:
      const meetingsList = action.meetings.map((meeting) => (
        {...meeting,
          detailedMeeting: {}
        }
      ));

      let notifications = meetingsList.length > 0 ? 0 : -1;
      if(meetingsList.length > 0 && !equalMeetingsLists(state.meetings, meetingsList)){
        MeetingNotification(state.meetings);
        notifications = 1;
      }

      return {
        ...state,
        meetings: meetingsList,
        services: action.services,
        notifications: notifications > 0
          ? state.notifications + notifications
          : 0
      };

    case C.SET_DETAILED_MEETING:
      const index = state.meetings.findIndex( meeting => meeting.meetingId === action.meetingId);
      if(index === -1) {
        return state;
      }

      let list = state.meetings.map( meeting => meeting);
      const currentMeeting = list[index];
      const newMeeting = {
        ...currentMeeting,
        detailedMeeting: action.detailedMeeting
      };
      list.splice(index, 1, newMeeting);

      return{
        ...state,
        meetings: list
      };

    case C.UPDATE_NOTIFICATIONS:
      let newNotifications = state.notifications + 1;
      return {
        ...state,
        notifications: newNotifications
      };

    case C.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };

    case C.TOGGLE_SERVICES_MODAL:
      return {
        ...state,
        servicesModalVisible: action.flag
      };

    case C.UPDATE_CONNECTED_STATUS:
      return {
        ...state
      };

    case C.START_MEETING:
      return {
        ...state,
        isLoading: action.isLoading,
        meetingUrl: action.meetingUrl
      };

    case C.MEETING_ADVISER_LOG_ID:
      return {
        ...state,
        meetingLogId: action.meetingLogId
      };

    default:
      return state;
  }
}