// Types
import { historyTypes } from '../../types';

// Initial State
const initialState = {
  isFetching: false,
  record: {},
  reportParams: []
};

export default function historyReducer (state = initialState, action) {
  switch (action.type) {
    case historyTypes.GET_REPORT:
      return {
        ...state,
        isFetching: action.isFetching,
        record: action.record
      };
    case historyTypes.GET_REPORT_PARAMS:
      return {
        ...state,
        isFetching: action.isFetching,
        reportParams: action.reportParams
      };
    case historyTypes.GENERATE_REPORT:
      return {
        ...state,
        isFetching: action.isFetching
      };
    default:
      return state;
  }
}