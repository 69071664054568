//Libraries
import React from 'react';
import {notification, Icon} from 'antd';

//Styles
import '../../../../styles/adviser/adviser.css';
import sound from '../../../../assets/sounds/notification.mp3';
import {REQUEST_STATUS_IDs} from "../../../../lib/generalUtils/constants";
import addNotification from "react-push-notification";

let interval = undefined;
let audio = undefined;

const MeetingNotification = (meetings) => {
  let inMeeting = false;
  for(let i = 0; i < meetings.length; i++){
    if(meetings[i].status === REQUEST_STATUS_IDs.inMeeting){
      inMeeting = true;
      break;
    }
  }

  if(!inMeeting)
    configureInterval();

  addNotification({
    title: 'Nueva reunión - Casa Propia en Línea',
    subtitle: 'Hay un cliente esperando ser atendido.',
    message: 'Hay un cliente esperando ser atendido.',
    theme: 'light',
    duration: 20000,
    native: true // when using native, your OS will handle theming.
  });

  notification.open({
    message: 'Cambios en las reuniones',
    description: 'El listado de reuniones ha cambiado.',
    icon: <Icon type="bell" className='meeting-notification' theme='filled'/>,
    duration: 0,
    onClose: () => stopNotificationSound()
  });
};

const configureInterval = () => {
  playNotificationSound();
  interval = setInterval( playNotificationSound, 5000);
};

const playNotificationSound = () => {
  audio = new Audio(sound);
  audio.autoplay = true;
  audio.play().then((response) => {
    console.log(response)
  }).catch((error) => {
    console.log(error)
  });
};

const stopNotificationSound = () => {
  audio.pause();
  clearInterval(interval);
};

export const stopNotificationSoundFunction = () => {
  if(audio !== undefined){
    audio.pause();
    clearInterval(interval);
  }
};

export default MeetingNotification;