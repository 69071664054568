//Constants
import { designerAdminTypes as C } from "../../types";

const initialState = {
  isLoading: false,
  //---------- Banner
  bannerConfig: {},
  //--------- Buttons
  buttonsList: [],
  countriesList: [],
  contactNotAvailableText: null,
  //--------- Curtain
  curtainViewConfig: {
    bannerConfig: {},
    buttons: []
  },
  //---------- Popup
  modalInfo: {}
};

export default function designerAdminReducer(state = initialState, action) {
  switch (action.type) {
    case C.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };

    // ------------------------- Banner -------------------------------
    case C.GET_BANNER_CONFIG:
      return {
        ...state,
        bannerConfig: action.bannerConfig
      };
    // ------------------------- Buttons -------------------------------
    case C.GET_BUTTONS:
      return {
        ...state,
        buttonsList: action.buttonsList
      };
    case C.GET_COUNTRIES_INFO:
      return {
        ...state,
        countriesList: action.countriesList,
        contactNotAvailableText: action.contactNotAvailableText,
      };

    // ------------------------- Curtain -------------------------------
    case C.GET_CURTAIN_VIEW_CONFIG:
      return {
        ...state,
        curtainViewConfig: action.curtainViewConfig
      };

    // ------------------------- Popup -------------------------------
    case C.GET_MODAL_INFO:
      return {
        ...state,
        modalInfo: action.modalInfo
      };

    default:
      return state;
  }
}