//Constants
import {authenticationTypes as C} from  '../../types';

const initialState = {
  isLogin: null,
  isNotLogged: null,
};

export default function loginReducer(state = initialState, action){
  switch (action.type) {
    case C.LOGGING:
      return{
        ...state,
        isLogin: action.payload,
        isNotLogged: null,  
      };
    case C.LOGOUT:
      return{
        ...state,
        isLogin: false,
        isNotLogged: false,  
      };
    case C.RESET_VALUES:
      return{
        ...state,
        isNotLogged: null,
        isLogin: null,
      };
    default:
      return state;
  }
}