//Constants
import {partnerTypes as C} from  '../../types';

const initialState = {
  isFetching: false,
  exitScheduleMeeting: false,
  requestInfo: {},
  requestTermsInfo: "",
  requestError: null,
  requestMeetingResponse: null,
  requestCancelInfo: "",
  requestLeaveInfo: null,
  requestAskForMeeting: null,
  finishedMeetingResponse: null,
  scheduleAvailabilityList: [],
  minTime: "00:00:00",
  maxTime: "24:00:00",
  rangeInMinutes: 60,
  scheduleMeetingInfo: {},
  advisoryRatingJustifications: [],
  callRatingJustifications: [],
  meetingLogId: null,
  countryCodeSelected: null,
};

export default function partnerReducer(state = initialState, action){
  switch (action.type) {
    case C.GET_REQUEST_INFO:
      return{
        ...state,
        requestInfo: action.payload,
      };
    case C.GET_TERMS_INFO:
      return{
        ...state,
        requestTermsInfo: action.payload,
      };
    case C.REQUEST_MEETING:
      return{
        ...state,
        requestMeetingResponse: action.payload,
        requestError: action.error
      };
    case C.CANCEL_MEETING:
      return{
        ...state,
        requestCancelInfo: action.payload,
      };
    case C.LEAVE_MEETING:
      return{
        ...state,
        requestLeaveInfo: action.payload,
      };
    case C.ASK_FOR_MEETING:
      return{
        ...state,
        requestAskForMeeting: action.payload,
      };
    case C.FINISHED_MEETING:
      return{
        ...state,
        finishedMeetingResponse: action.payload,
      };
    case C.GET_SCHEDULE_AVAILABILITY:
      return{
        ...state,
        minTime: action.minTime,
        maxTime: action.maxTime,
        isFetching: action.isFetching,
        scheduleAvailabilityList: action.scheduleAvailabilityList,
        rangeInMinutes: action.rangeInMinutes
      };
      case C.SCHEDULE_MEETING:
        return{
          ...state,
          isFetching: action.isFetching,
          exitScheduleMeeting: action.exitScheduleMeeting,
          scheduleMeetingInfo: action.scheduleMeetingInfo
        };
    case C.RESET_PARTNER_VALUES:
      return{
        ...state,
        requestInfo: {},
        requestTermsInfo: "",
        requestMeetingResponse: null,
        finishedMeetingResponse: null,
        requestCancelInfo: "",
        requestLeaveInfo: null,
        requestAskForMeeting: null,
        exitScheduleMeeting: false,
        scheduleMeetingInfo: {}
      };

    case C.SET_QUALIFICATION_PARAMS:
      return {
        ...state,
        advisoryRatingJustifications: action.advisoryRatingJustifications,
        callRatingJustifications: action.callRatingJustifications
      };

    case C.JOIN_SCHEDULE_MEETING_BY_ID:
      return {
          ...state,
        isFetching: action.isFetching,
        scheduleMeetingInfo: action.payload,
        requestError: action.requestError
      };

    case C.GET_RENDER_VIEW:
      return {
          ...state,
        requestCancelInfo: action.requestCancelInfo
      };
    case C.MEETING_LOG_ID:
      return {
          ...state,
        meetingLogId: action.meetingLogId
      };
    case C.GET_LOCALITATION_IP:
      return {
        ...state,
        countryCodeSelected: action.countryCodeSelected
      }
    default:
      return state;
  }
}