export const EmptyListMessage = 'No hay para mostrar';

export const Roles = {
  DesignerAdmin: 10,
  Profiler: 9,
  Partner: 8,
  Adviser: 7,
  Geek: 1,
  Admin: 2,
  Director: 3
};

export const SERVICES = {
  sales: 'Ventas',
  credits: 'Créditos',
  salesId: 1,
  creditsId: 2,
};

export const REQUEST_STATUS_IDs = {
  assigned: 2,
  inMeeting: 6,
};