/*--------------- AccountManagement ---------------*/
export const authenticationTypes = {
  LOGGING: "LOGGING",
  LOGOUT: "LOGOUT",
  RESET_VALUES: "RESET_VALUES"
};

/*----------------- Partner  ----------------------*/
export const partnerTypes = {
  GET_REQUEST_INFO: "GET_REQUEST_INFO",
  GET_TERMS_INFO: "GET_TERMS_INFO",
  REQUEST_MEETING: "REQUEST_MEETING",
  CANCEL_MEETING: "CANCEL_MEETING",
  LEAVE_MEETING: "LEAVE_MEETING",
  ASK_FOR_MEETING: "ASK_FOR_MEETING",
  GET_SCHEDULE_AVAILABILITY: "GET_SCHEDULE_AVAILABILITY",
  RESET_VALUES: "RESET_VALUES",
  SET_QUALIFICATION_PARAMS: "SET_QUALIFICATION_PARAMS",
  FINISHED_MEETING: 'FINISHED_MEETING',
  SCHEDULE_MEETING: "SCHEDULE_MEETING",
  RESET_PARTNER_VALUES: 'RESET_PARTNER_VALUES',
  JOIN_SCHEDULE_MEETING_BY_ID: 'JOIN_SCHEDULE_MEETING_BY_ID',
  GET_RENDER_VIEW: 'GET_RENDER_VIEW',
  MEETING_LOG_ID: 'MEETING_LOG_ID',
  GET_LOCALITATION_IP: "GET_LOCALITATION_IP",
};

/*------------------ Profiler ---------------------*/
export const profilerTypes = {
  GET_ALL_ADVISERS: 'GET_ALL_ADVISERS',
  GET_ALL_TO_ASSIGN: 'GET_ALL_TO_ASSIGN',
  DELETE_MEETING_REQUEST: 'DELETE_MEETING_REQUEST',
  ASSIGN: 'ASSIGN',
  GET_ALL_ASSIGNED_MEETING: 'GET_ALL_ASSIGNED_MEETING',
  GET_SCHEDULE_PARAMS: 'GET_SCHEDULE_PARAMS',
  ADD_SCHEDULE: 'ADD_SCHEDULE',
  GET_SCHEDULE: 'GET_SCHEDULE',
  UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
  GET_SCHEDULE_SUMMARY: 'GET_SCHEDULE_SUMMARY',
  SET_IDLE_TIMEOUT: 'SET_IDLE_TIMEOUT',
  SET_IS_FETCHING_PROF: 'SET_IS_FETCHING_PROF',
  GET_ALL_IN_A_MEETING: 'GET_ALL_IN_A_MEETING',
  FREE_MEETING: 'FREE_MEETING'
};

/*------------------ History ---------------------*/
export const historyTypes = {
  GET_REPORT: 'GET_REPORT',
  GET_REQUESTS_REPORT: 'GET_REQUESTS_REPORT',
  GET_REPORT_PARAMS: 'GET_REPORT_PARAMS',
  GET_REQUESTS_REPORT_PARAMS: 'GET_REQUESTS_REPORT_PARAMS',
  GENERATE_REPORT: 'GENERATE_REPORT'
};

/*----------------- Adviser -----------------------*/
export const AdviserTypes = {
  SET_ADVISER_HISTORY: 'SET_ADVISER_HISTORY',
  SET_ADVISER_MEETINGS: 'SET_ADVISER_MEETINGS',
  SET_DETAILED_MEETING: 'SET_DETAILED_MEETING',
  UPDATE_NOTIFICATIONS: 'UPDATE_NOTIFICATIONS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  TOGGLE_SERVICES_MODAL: 'TOGGLE_SERVICES_MODAL',
  UPDATE_CONNECTED_STATUS: 'UPDATE_CONNECTED_STATUS',
  START_MEETING: 'START_MEETING',
  MEETING_ADVISER_LOG_ID: 'MEETING_ADVISER_LOG_ID'
};

/*----------------- Admin -----------------------*/
export const AdminTypes = {
  GET_LOGOS: 'GET_LOGOS',
  SET_LOGOS: 'SET_LOGOS',
  GET_PARAMS_TEXT: 'GET_PARAMS_TEXT',
  SET_PARAMS_TEXT: 'SET_PARAMS_TEXT',
  GET_MULTIMEDIA: 'GET_MULTIMEDIA',
  UPDATE_MULTIMEDIA: 'UPDATE_MULTIMEDIA',
  GET_TERMS_CONDS: 'GET_TERMS_CONDS',
  UPDATE_TERMS_CONDS: 'SET_TERMS_CONDS',
  GET_LINKS: 'GET_LINKS',
  ADD_LINKS: 'ADD_LINKS',
  DELETE_LINKS: 'DELETE_LINKS',
  GET_SCHEDULE: 'GET_SCHEDULE',
  UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
  GET_SERVICES: 'GET_SERVICES',
  ADD_SERVICES: 'ADD_SERVICES',
  UPDATE_SERVICES: 'UPDATE_SERVICES',
  DELETE_SERVICES: 'DELETE_SERVICES',
  GET_TIMEOUT: 'GET_TIMEOUT',
  UPDATE_TIMEOUT: 'UPDATE_TIMEOUT',
  GET_KEY: 'GET_KEY',
  UPDATE_KEYS: 'UPDATE_ZOOM_KEYS',
  RESET_VALUES: "RESET_VALUES",
  GET_VIDEOCALL_URLS: 'GET_VIDEOCALL_URLS',
  GET_URL_BY_ID: 'GET_URL_BY_ID',
  ADD_VIDEOCALL_URL: 'ADD_VIDEOCALL_URL',
  UPDATE_VIDEOCALL_URL: 'ADD_VIDEOCALL_URL',
  DELETE_VIDEOCALL_URL: 'DELETE_VIDEOCALL_URL',
  GET_PARAMS_VIDEOCALL_URL: 'GET_PARAMS_VIDEOCALL_URL',
  GET_EMBEDDED_MODE: 'GET_EMBEDDED_MODE',
  UPDATE_EMBEDDED_MODE: 'UPDATE_EMBEDDED_MODE',
};

/*---------------- Designer Admin ---------------------*/
export const designerAdminTypes = {
  SET_LOADING: 'SET_LOADING',
  GET_CURTAIN_VIEW_CONFIG: 'GET_CURTAIN_VIEW_CONFIG',
  GET_BUTTONS: 'GET_BUTTONS',
  GET_BANNER_CONFIG: 'GET_BANNER_CONFIG',
  SET_BANNER_CONFIG: 'SET_BANNER_CONFIG',
  GET_COUNTRIES_INFO: 'GET_COUNTRIES_INFO',
  GET_MODAL_INFO: 'GET_MODAL_INFO'
};

/*---------------- Header Menu ---------------------*/
export const headerMenuTypes = {
  CURRENT_KEY_ACTIVE: 'CURRENT_KEY_ACTIVE'
};